import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"
import Alert from "@material-ui/lab/Alert"

import { Container, makeStyles, Grid, Box, Typography } from "@material-ui/core"

import SEO from "../components/seo"
import BookList from "../components/book-list"
import Breadcrumbs from "../components/breadcrumbs"

import BgImage from "../../static/images/aboutBg.svg"
import Logo from "../../static/images/logo/logo_signup.svg"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
import CategoryImg from "../../static/images/Icons/categoryImage.png"
import FooterCategory1 from "../../static/images/Icons/CategoryIcons1.svg"
import FooterCategory2 from "../../static/images/Icons/CategoryIcons2.svg"
import FooterCategory3 from "../../static/images/Icons/CategoryIcons3.svg"

const CategoryTemplate = ({ data, pageContext }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const { id, title, pLang, categoryImage, strapiId, backgroundColor } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", function () {
      windowGlobal.history.pushState(pLang, `category`, `/${i18n.language}/categories/${strapiId}`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `category`, `/${i18n.language}/categories/${strapiId}`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/categories/${strapiId}`
      }
    })
  }, [i18n, pLang, strapiId])

  const books = []
  for (let book of data.allStrapiBooks.nodes) {
    let objCopy = Object.assign({}, book)
    books.push(objCopy)
  }

  let parent_cat_name
  let parent_cat_link

  const titleForLang =
    i18n.language === "ar"
      ? data.strapiCategories.title_ar
      : i18n.language === "tr"
      ? data.strapiCategories.title_tr
      : i18n.language === "en" && data.strapiCategories.title

  if (data.allStrapiCategories.nodes.filter(item => item.id === id)[0].parent_cat) {
    data.allStrapiCategories.nodes
      .filter(item => item.id === id)
      .map(category => (
        <>
          {
            (parent_cat_name =
              i18n.language === "ar"
                ? category.parent_cat.title_ar
                : i18n.language === "tr"
                ? category.parent_cat.title_tr
                : i18n.language === "en" && category.parent_cat.title)
          }
          {(parent_cat_link = `${i18n.language}/categories/${category.parent_cat.id}`)}
        </>
      ))
  }

  const breadcrumbs = [
    {
      text: t("breadcrumbs.categories"),
      link: `${i18n.language}/categories`,
    },
    {
      text: parent_cat_name,
      link: parent_cat_link,
    },
    {
      text: titleForLang,
    },
  ]

  const testSubCatsExisting = data.strapiCategories
    ? i18n.language === "en"
      ? data.strapiCategories.sub_cats.map(item => item.title)
      : i18n.language === "ar"
      ? data.strapiCategories.sub_cats.map(item => item.title_ar)
      : i18n.language === "tr" && data.strapiCategories.sub_cats.map(item => item.title_tr)
    : []

  return (
    <>
      <SEO title={title} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} text={title} />
      {/* <PageTitle title={title} image={categoryImage} /> */}
      <Container className={`${classes.fullWidth} ${classes.heroBg}`}>
        <Grid container className={classes.categoryGridContainer}>
          <Grid item sm={12} xs={12} md={3} className={classes.sideBarGrid} component="div">
            <Container className={classes.sideBarContainer}>
              <Box className={classes.categorySideBarContent}>
                <br />
                <div
                  className={`${classes.catImage} catImage`}
                  style={{
                    backgroundColor:
                      backgroundColor && backgroundColor.startsWith("#")
                        ? backgroundColor
                        : backgroundColor !== null
                        ? `#${backgroundColor}`
                        : "rgb(0, 62, 150)",
                  }}
                >
                  {/* <div className="catLine" style={{backgroundColor: "#003e96"}} /> */}
                  {data.strapiCategories.image && data.strapiCategories.image.childImageSharp ? (
                    // <img src={data.strapiCategories.image.publicURL} style={{heigth: "76px"}} />
                    <Img fixed={data.strapiCategories.image.childImageSharp.fixed} />
                  ) : categoryImage !== null ? (
                    <img alt="category" src={categoryImage} />
                  ) : (
                    <img alt="category" src={CategoryImg} />
                  )}
                  <Typography className={classes.catTitle}>{titleForLang}</Typography>
                </div>
                <br />
                {data.allStrapiCategories.nodes.map(category => (
                  <div key={category.strapiId}>
                    <Link
                      to={`/${i18n.language}/categories/${category.strapiId}`}
                      // activeClassName={classes.categoryActive}
                      className={`${classes.categorySideBarItem} ${classes.categorySideBarMainItem} ${
                        data.strapiCategories.strapiId === category.strapiId && classes.categoryActive
                      }`}
                    >
                      <Typography>
                        {i18n.language === "en" && category.title}
                        {i18n.language === "ar" && category.title_ar}
                        {i18n.language === "tr" && category.title_tr}
                      </Typography>
                    </Link>
                  </div>
                ))}
              </Box>
              <Box className={classes.categorySideBarBottom}>
                <img src={Logo} alt="sideBar logo" className={classes.categorySideBarLogo} />
                <Typography variant="subtitle2" component="p" className={classes.categorySideBarBottomText}>
                  {t("home.heroText1")}
                </Typography>
                <Typography variant="subtitle2" component="p" className={classes.categorySideBarBottomText}>
                  {t("home.heroText2")}
                </Typography>

                <Box display="flex" justifyContent="center">
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory1} alt="Foliar" />
                    <Typography variant="subtitle1">{t("footer.foliar")}</Typography>
                  </Box>
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory2} alt="Electronic" />
                    <Typography variant="subtitle1">{t("footer.electronic")}</Typography>
                  </Box>
                  <Box p={1} m={1} className={classes.typesIcons}>
                    <img src={FooterCategory3} alt="Vocal" />
                    <Typography variant="subtitle1">{t("footer.vocal")}</Typography>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box className={classes.categorySideBarStoreIcons1}>
                    <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                      <img src={StoreIcons1} alt="Google Play" />
                    </a>
                  </Box>
                  <Box className={classes.categorySideBarStoreIcons2}>
                    <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                      <img src={StoreIcons2} alt="App Store" />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Grid>
          <Grid item container sm={12} md={9} className={classes.contentGrid} component="div">
            {testSubCatsExisting.length > 0 && testSubCatsExisting[0] !== null && (
              <Container className={classes.categoriesSection}>
                <Container>
                  <Grid item xs={12}>
                    <Typography variant="h4" component="h2" className={classes.secTitleCategories}>
                      {t("home.categoriesTitle")}
                    </Typography>
                  </Grid>
                  <Grid container spacing={4} className={classes.categoryGrid}>
                    {data.allStrapiCategories.nodes
                      .filter(item => item.id === id)
                      .map(
                        category =>
                          category.sub_cats &&
                          category.sub_cats.map(category => (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              lg={3}
                              md={3}
                              key={category.id}
                              className={classes.categoryGridItem}
                            >
                              <Link to={`/${i18n.language}/categories/${category.id}`} className={classes.categoryLink}>
                                <Box
                                  p={1}
                                  bgcolor={
                                    category.backgroundColor && category.backgroundColor.startsWith("#")
                                      ? category.backgroundColor
                                      : category.backgroundColor !== null
                                      ? `#${category.backgroundColor}`
                                      : "rgb(0, 62, 150)"
                                  }
                                  className={classes.categoryBoxes}
                                >
                                  {/* <img
                                      p={3}
                                      src={
                                        // category.image
                                        //   ? category.image.publicURL
                                        //   : 
                                          CategoryImg
                                      }
                                      alt={category.title}
                                    /> */}
                                  {category.image ? (
                                    <img src={category.image.publicURL} style={{height: "76px"}} />
                                    // <Img fixed={category.image.childImageSharp.fixed} />
                                  ) : (
                                    <img
                                      p={3}
                                      src={
                                        // category.image
                                        //   ? category.image.publicURL
                                        //   :
                                        CategoryImg
                                      }
                                      style={{ maxWidth: "90%", maxHeight: 76 }}
                                      alt={category.title}
                                    />
                                  )}
                                  <Typography variant="h5" component="p">
                                    {i18n.language === "en" && category.title}
                                    {i18n.language === "ar" && category.title_ar}
                                    {i18n.language === "tr" && category.title_tr}
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                          ))
                      )}
                  </Grid>
                </Container>
              </Container>
            )}
            {books.length < 1 && testSubCatsExisting.length < 1 && testSubCatsExisting.length < 1 && (
              <Grid item xs={12} md={12}>
                <Alert severity="warning" variant="outlined" className={classes.noContent}>
                  <Typography>{t("pages.noCategoryContentFound")}</Typography>
                </Alert>
              </Grid>
            )}
            <Container className={classes.categoriesSectionBooks}>
              <BookList books={books} />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default CategoryTemplate

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  sideBarGrid: {
    backgroundColor: "#f5f5f5",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 50,
      "&:after": {
        content: "''",
        backgroundImage: "linear-gradient(180deg, #f5f5f5, transparent)",
        position: "absolute",
        left: 0,
        bottom: "-50px",
        height: 50,
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      paddingBottom: "2rem",
      "&:after": {
        content: "",
        display: "none",
      },
    },
  },
  // contentGrid: {
  //   paddingBottom: "7rem",
  // },
  heroBg: {
    backgroundColor: "#fff",
  },
  sideBarContainer: {
    textAlign: "center",
  },
  categorySideBarContent: {
    width: 180,
    margin: "1rem auto 0",
    textAlign: "left",
  },
  categorySideBarItem: {
    color: "#707070",
    textDecoration: "none",
    "& p": {
      lineHeight: "34px",
    },
  },
  categoryActive: {
    color: "#003e96",
    "& p": {
      fontWeight: "bold",
    },
  },
  categorySideBarLogo: {
    margin: "0 1rem 1rem",
    width: "calc(100% - 40px)",
  },
  categorySideBarBottom: {
    width: 200,
    margin: "2rem auto 1rem",
  },
  categorySideBarBottomText: {
    fontSize: "0.8rem",
    marginBottom: "0.8rem",
  },
  typesIcons: {
    "& img": {
      height: 60,
      width: "auto",
    },
  },
  categorySideBarStoreIcons1: {
    "& img": {
      height: 42,
      marginTop: 1,
    },
  },
  categorySideBarStoreIcons2: {
    "& img": {
      height: 42,
    },
  },
  categoryGridContainer: {
    "&:first-child": {
      alignItems: "stretch",
    },
    "&:last-child": {
      alignItems: "flex-start",
    },
  },
  categoriesSection: {
    position: "relative",
    backgroundColor: "#e9e9e9",
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    margin: 0,
    paddingTop: "3rem",
    paddingBottom: "3rem",
    maxWidth: "100%",
    "&:after": {
      content: "''",
      backgroundImage: `url(${BgImage})`,
      backgroundSize: "cover",
      backgroundPosition: "150px 250px",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      zIndex: "2",
    },
    "& > div": {
      position: "relative",
      zIndex: 3,
    },
  },
  categoriesSectionBooks: {
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(5),
    margin: 0,
    maxWidth: "100%",
  },
  secTitleCategories: {
    marginBottom: "1.5rem",
    textAlign: "center",
    color: "#003e96",
  },
  categoryLink: {
    textDecoration: "none",
    "& img": {
      // maxHeight: 76,
      // maxWidth: "90%",
    },
  },
  categoryBoxes: {
    textAlign: "center",
    color: "white",
    paddingTop: 20,
    paddingBottom: 20,
    border: "solid 1px #707070",
    borderRadius: 10,
    "& p": {
      textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
      fontSize: "1.3rem",
    },
  },
  catTitle: {
    bottom: 5,
    left: "50%",
    fontSize: 20,
    width: "100%",
    fontWeight: 600,
    color: "#fff",
  },
  catImage: {
    color: "white",
    border: "solid 1px #707070",
    textAlign: "center",
    paddingTop: 20,
    borderRadius: 10,
    paddingBottom: 20,
    "& img": {
      maxHeight: 76,
    },
  },
  noContent: {
    width: "100%",
    maxWidth: 767,
    margin: "0 auto",
    lineHeight: 1.8,
    fontSize: 16,
    marginTop: 16,
    "& .MuiSvgIcon-root": {
      alignSelf: "center",
    },
  },
}))

export const query = graphql`
  query CategoryTemplate($strapiId: Int!, $slug: String!) {
    allStrapiBooks(filter: {categories: {elemMatch: {id: {eq: $strapiId}}}, book_items: {elemMatch: {type: {eq: "paper"}}}}) {
      nodes {
        id
        slug
        ISBN
        title
        rating
        title_ar
        title_tr
        quantity
        strapiId
        image {
          publicURL
        }
        book_items {
          id
          price
          title
          type
        }
      }
    }
    allStrapiCategories {
      nodes {
        id
        strapiId
        slug
        title
        title_ar
        title_tr
        backgroundColor
        image {
          publicURL
        }
        parent_cat {
          id
          slug
          title
          title_ar
          title_tr
          image {
            publicURL
          }
        }
        sub_cats {
          id
          slug
          title
          title_ar
          title_tr
          parent_cat
          image {
            publicURL
          }
          backgroundColor
        }
      }
    }
    strapiCategories(slug: { eq: $slug }) {
      id
      strapiId
      slug
      title
      title_ar
      title_tr
      backgroundColor
      image {
        publicURL
      }
      parent_cat {
        id
        title
        title_ar
        title_tr
        image {
          publicURL
        }
      }
      sub_cats {
        id
        title
        title_ar
        title_tr
        parent_cat
        image {
          publicURL
        }
        backgroundColor
      }
    }
  }
`

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document


// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }
// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }
// childImageSharp {
//   fixed(width: 169, height: 244) {
//     ...GatsbyImageSharpFixed
//   }
// }
// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }

// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }

// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }

// childImageSharp {
//   fixed(height: 76) {
//     ...GatsbyImageSharpFixed
//   }
// }



